export const Abstracts = {
  1: '/images/abstracts/1.png',
  2: '/images/abstracts/2.png',
  3: '/images/abstracts/3.png',
  4: '/images/abstracts/4.png',
  5: '/images/abstracts/5.png',
  6: '/images/abstracts/6.png',
}

export const Products = {
  Interviews: '/images/product/interviews.png',
}

export const Tours = {
  Welcome: {
    GetStarted: '/images/tours/welcome/GetStarted.png',
    ProductFeedback: '/images/tours/welcome/product-feedback.png',
    CustomerInterviews: '/images/tours/welcome/customer-interviews.png',
    OpportunitiesAndSolutions: '/images/tours/welcome/opportunities-and-solutions.png',
    Strategy: '/images/tours/welcome/strategy.png',
    Modules: '/images/tours/welcome/modules.png',
  },
}

export const LoginBackground = '/images/login-background.png'
export const SignupBackground = '/images/signup-background.png'
export const Brands = '/images/brands.png'
export const Logo = '/images/logo.png'
